import axios from 'axios'
import { ObjectId } from 'bson'

export const siteTeamIds = ['619f96b71b6aaa25f53fec3c', '619f91d32b2b45517a26b39b']
export const siteCurrency = 'MYR'// todo fix later
export const siteChannelId = new ObjectId('61aced2f3a838a4bc4a35c13')
export const siteRootCategoryId = new ObjectId('628cbb603301bc6655576fc8')
export const siteHomeCategoryId = new ObjectId('628bbf41828cbba380ae1bbb')
export const siteURL = () => {
  return 'https://www.cel-elements.com'
  // const url = window.location.protocol + '://'+ window.location.host
  // return url
}
export const apiURL = () => 'https://ap-southeast-1.aws.data.mongodb-api.com/app/msshop2-nwtia/endpoint'

export interface CurrencyConfig {
  [currency: string]: CurrencySymbol
}
export interface CurrencySymbol {
  currency: string
  abbreviation: string
  symbol: string
}
const cachedCurrencies = ref<CurrencyConfig>({})
const loadedCurrency = ref(false)

export const currencies = computed<CurrencyConfig>(() => {
  if (!loadedCurrency.value) {
    axios.get('/js/currency-symbols.json').then(async (resp) => {
      console.log('cyrrency symbols', resp)
      cachedCurrencies.value = {}

      const res = await resp.data
      console.log('currencies?', res)
      res.forEach((r) => {
        cachedCurrencies.value[r.abbreviation] = r
      })
      loadedCurrency.value = true
    })
  }
  return cachedCurrencies.value
})

export const siteIsPhitomas = () => {
  return (
    typeof window !== 'undefined'
    && ['merchant.mercstudio.com', 'phitomas.local'].includes(
      window.location.hostname,
    )
  )
}

export const siteIsCelelements = () => {
  return (
    typeof window !== 'undefined'
    && (window.location.hostname.endsWith('cel-elements.com')
      || window.location.hostname.endsWith('cel.local'))
  )
}

export const siteIsVitaHealth = () => {
  return (
    typeof window !== 'undefined'
    && window.location.hostname.endsWith('adsperianx.com.my')
  )
}

export const siteUploadPrefix = () => {
  if (siteIsPhitomas()) {
    return 'phitomas/up/u'
  }
  if (siteIsCelelements()) {
    return 'celeleme/up/u'
  }

  if (siteIsVitaHealth()) {
    return 'vitahealth/up/u'
  }

  return 'dev/up/u'
}
